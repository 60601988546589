import * as Svg from "../svg/Icons";

export enum IconNames {
  AddressBook = "AddressBook",
  AirTrafficControl = "AirTrafficControl",
  Airplane = "Airplane",
  AirplaneInFlight = "AirplaneInFlight",
  AirplaneLanding = "AirplaneLanding",
  AirplaneTakeoff = "AirplaneTakeoff",
  AirplaneTilt = "AirplaneTilt",
  Airplay = "Airplay",
  Alarm = "Alarm",
  Alien = "Alien",
  AlignBottom = "AlignBottom",
  AlignBottomSimple = "AlignBottomSimple",
  AlignCenterHorizontal = "AlignCenterHorizontal",
  AlignCenterHorizontalSimple = "AlignCenterHorizontalSimple",
  AlignCenterVertical = "AlignCenterVertical",
  AlignCenterVerticalSimple = "AlignCenterVerticalSimple",
  AlignLeft = "AlignLeft",
  AlignLeftSimple = "AlignLeftSimple",
  AlignRight = "AlignRight",
  AlignRightSimple = "AlignRightSimple",
  AlignTop = "AlignTop",
  AlignTopSimple = "AlignTopSimple",
  AmazonLogo = "AmazonLogo",
  Anchor = "Anchor",
  AnchorSimple = "AnchorSimple",
  AndroidLogo = "AndroidLogo",
  AngularLogo = "AngularLogo",
  Aperture = "Aperture",
  AppStoreLogo = "AppStoreLogo",
  AppWindow = "AppWindow",
  AppleLogo = "AppleLogo",
  ApplePodcastsLogo = "ApplePodcastsLogo",
  Archive = "Archive",
  ArchiveBox = "ArchiveBox",
  ArchiveTray = "ArchiveTray",
  Armchair = "Armchair",
  ArrowArcLeft = "ArrowArcLeft",
  ArrowArcRight = "ArrowArcRight",
  ArrowBendDoubleUpLeft = "ArrowBendDoubleUpLeft",
  ArrowBendDoubleUpRight = "ArrowBendDoubleUpRight",
  ArrowBendDownLeft = "ArrowBendDownLeft",
  ArrowBendDownRight = "ArrowBendDownRight",
  ArrowBendLeftDown = "ArrowBendLeftDown",
  ArrowBendLeftUp = "ArrowBendLeftUp",
  ArrowBendRightDown = "ArrowBendRightDown",
  ArrowBendRightUp = "ArrowBendRightUp",
  ArrowBendUpLeft = "ArrowBendUpLeft",
  ArrowBendUpRight = "ArrowBendUpRight",
  ArrowCircleDown = "ArrowCircleDown",
  ArrowCircleDownLeft = "ArrowCircleDownLeft",
  ArrowCircleDownRight = "ArrowCircleDownRight",
  ArrowCircleLeft = "ArrowCircleLeft",
  ArrowCircleRight = "ArrowCircleRight",
  ArrowCircleUp = "ArrowCircleUp",
  ArrowCircleUpLeft = "ArrowCircleUpLeft",
  ArrowCircleUpRight = "ArrowCircleUpRight",
  ArrowClockwise = "ArrowClockwise",
  ArrowCounterClockwise = "ArrowCounterClockwise",
  ArrowDown = "ArrowDown",
  ArrowDownLeft = "ArrowDownLeft",
  ArrowDownRight = "ArrowDownRight",
  ArrowElbowDownLeft = "ArrowElbowDownLeft",
  ArrowElbowDownRight = "ArrowElbowDownRight",
  ArrowElbowLeft = "ArrowElbowLeft",
  ArrowElbowLeftDown = "ArrowElbowLeftDown",
  ArrowElbowLeftUp = "ArrowElbowLeftUp",
  ArrowElbowRight = "ArrowElbowRight",
  ArrowElbowRightDown = "ArrowElbowRightDown",
  ArrowElbowRightUp = "ArrowElbowRightUp",
  ArrowElbowUpLeft = "ArrowElbowUpLeft",
  ArrowElbowUpRight = "ArrowElbowUpRight",
  ArrowFatDown = "ArrowFatDown",
  ArrowFatLeft = "ArrowFatLeft",
  ArrowFatLineDown = "ArrowFatLineDown",
  ArrowFatLineLeft = "ArrowFatLineLeft",
  ArrowFatLineRight = "ArrowFatLineRight",
  ArrowFatLineUp = "ArrowFatLineUp",
  ArrowFatLinesDown = "ArrowFatLinesDown",
  ArrowFatLinesLeft = "ArrowFatLinesLeft",
  ArrowFatLinesRight = "ArrowFatLinesRight",
  ArrowFatLinesUp = "ArrowFatLinesUp",
  ArrowFatRight = "ArrowFatRight",
  ArrowFatUp = "ArrowFatUp",
  ArrowLeft = "ArrowLeft",
  ArrowLineDown = "ArrowLineDown",
  ArrowLineDownLeft = "ArrowLineDownLeft",
  ArrowLineDownRight = "ArrowLineDownRight",
  ArrowLineLeft = "ArrowLineLeft",
  ArrowLineRight = "ArrowLineRight",
  ArrowLineUp = "ArrowLineUp",
  ArrowLineUpLeft = "ArrowLineUpLeft",
  ArrowLineUpRight = "ArrowLineUpRight",
  ArrowRight = "ArrowRight",
  ArrowSquareDown = "ArrowSquareDown",
  ArrowSquareDownLeft = "ArrowSquareDownLeft",
  ArrowSquareDownRight = "ArrowSquareDownRight",
  ArrowSquareIn = "ArrowSquareIn",
  ArrowSquareLeft = "ArrowSquareLeft",
  ArrowSquareOut = "ArrowSquareOut",
  ArrowSquareRight = "ArrowSquareRight",
  ArrowSquareUp = "ArrowSquareUp",
  ArrowSquareUpLeft = "ArrowSquareUpLeft",
  ArrowSquareUpRight = "ArrowSquareUpRight",
  ArrowUDownLeft = "ArrowUDownLeft",
  ArrowUDownRight = "ArrowUDownRight",
  ArrowULeftDown = "ArrowULeftDown",
  ArrowULeftUp = "ArrowULeftUp",
  ArrowURightDown = "ArrowURightDown",
  ArrowURightUp = "ArrowURightUp",
  ArrowUUpLeft = "ArrowUUpLeft",
  ArrowUUpRight = "ArrowUUpRight",
  ArrowUp = "ArrowUp",
  ArrowUpLeft = "ArrowUpLeft",
  ArrowUpRight = "ArrowUpRight",
  ArrowsClockwise = "ArrowsClockwise",
  ArrowsCounterClockwise = "ArrowsCounterClockwise",
  ArrowsDownUp = "ArrowsDownUp",
  ArrowsHorizontal = "ArrowsHorizontal",
  ArrowsIn = "ArrowsIn",
  ArrowsInCardinal = "ArrowsInCardinal",
  ArrowsInLineHorizontal = "ArrowsInLineHorizontal",
  ArrowsInLineVertical = "ArrowsInLineVertical",
  ArrowsInSimple = "ArrowsInSimple",
  ArrowsLeftRight = "ArrowsLeftRight",
  ArrowsMerge = "ArrowsMerge",
  ArrowsOut = "ArrowsOut",
  ArrowsOutCardinal = "ArrowsOutCardinal",
  ArrowsOutLineHorizontal = "ArrowsOutLineHorizontal",
  ArrowsOutLineVertical = "ArrowsOutLineVertical",
  ArrowsOutSimple = "ArrowsOutSimple",
  ArrowsSplit = "ArrowsSplit",
  ArrowsVertical = "ArrowsVertical",
  Article = "Article",
  ArticleMedium = "ArticleMedium",
  ArticleNyTimes = "ArticleNyTimes",
  Asterisk = "Asterisk",
  AsteriskSimple = "AsteriskSimple",
  At = "At",
  Atom = "Atom",
  Baby = "Baby",
  Backpack = "Backpack",
  Backspace = "Backspace",
  Bag = "Bag",
  BagSimple = "BagSimple",
  Balloon = "Balloon",
  Bandaids = "Bandaids",
  Bank = "Bank",
  Barbell = "Barbell",
  Barcode = "Barcode",
  Barricade = "Barricade",
  Baseball = "Baseball",
  BaseballCap = "BaseballCap",
  Basket = "Basket",
  Basketball = "Basketball",
  Bathtub = "Bathtub",
  BatteryCharging = "BatteryCharging",
  BatteryChargingVertical = "BatteryChargingVertical",
  BatteryEmpty = "BatteryEmpty",
  BatteryFull = "BatteryFull",
  BatteryHigh = "BatteryHigh",
  BatteryLow = "BatteryLow",
  BatteryMedium = "BatteryMedium",
  BatteryPlus = "BatteryPlus",
  BatteryPlusVertical = "BatteryPlusVertical",
  BatteryVerticalEmpty = "BatteryVerticalEmpty",
  BatteryVerticalFull = "BatteryVerticalFull",
  BatteryVerticalHigh = "BatteryVerticalHigh",
  BatteryVerticalLow = "BatteryVerticalLow",
  BatteryVerticalMedium = "BatteryVerticalMedium",
  BatteryWarning = "BatteryWarning",
  BatteryWarningVertical = "BatteryWarningVertical",
  Bed = "Bed",
  BeerBottle = "BeerBottle",
  BeerStein = "BeerStein",
  BehanceLogo = "BehanceLogo",
  Bell = "Bell",
  BellRinging = "BellRinging",
  BellSimple = "BellSimple",
  BellSimpleRinging = "BellSimpleRinging",
  BellSimpleSlash = "BellSimpleSlash",
  BellSimpleZ = "BellSimpleZ",
  BellSlash = "BellSlash",
  BellZ = "BellZ",
  BezierCurve = "BezierCurve",
  Bicycle = "Bicycle",
  Binoculars = "Binoculars",
  Bird = "Bird",
  Bluetooth = "Bluetooth",
  BluetoothConnected = "BluetoothConnected",
  BluetoothSlash = "BluetoothSlash",
  BluetoothX = "BluetoothX",
  Boat = "Boat",
  Bone = "Bone",
  Book = "Book",
  BookBookmark = "BookBookmark",
  BookOpen = "BookOpen",
  BookOpenText = "BookOpenText",
  Bookmark = "Bookmark",
  BookmarkSimple = "BookmarkSimple",
  Bookmarks = "Bookmarks",
  BookmarksSimple = "BookmarksSimple",
  Books = "Books",
  Boot = "Boot",
  BoundingBox = "BoundingBox",
  BowlFood = "BowlFood",
  BracketsAngle = "BracketsAngle",
  BracketsCurly = "BracketsCurly",
  BracketsRound = "BracketsRound",
  BracketsSquare = "BracketsSquare",
  Brain = "Brain",
  Brandy = "Brandy",
  Bridge = "Bridge",
  Briefcase = "Briefcase",
  BriefcaseMetal = "BriefcaseMetal",
  Broadcast = "Broadcast",
  Broom = "Broom",
  Browser = "Browser",
  Browsers = "Browsers",
  BugBeetle = "BugBeetle",
  Bug = "Bug",
  BugDroid = "BugDroid",
  Buildings = "Buildings",
  Bus = "Bus",
  Butterfly = "Butterfly",
  Cactus = "Cactus",
  Cake = "Cake",
  Calculator = "Calculator",
  CalendarBlank = "CalendarBlank",
  Calendar = "Calendar",
  CalendarCheck = "CalendarCheck",
  CalendarPlus = "CalendarPlus",
  CalendarX = "CalendarX",
  CallBell = "CallBell",
  Camera = "Camera",
  CameraPlus = "CameraPlus",
  CameraRotate = "CameraRotate",
  CameraSlash = "CameraSlash",
  Campfire = "Campfire",
  Car = "Car",
  CarProfile = "CarProfile",
  CarSimple = "CarSimple",
  Cardholder = "Cardholder",
  Cards = "Cards",
  CaretCircleDoubleDown = "CaretCircleDoubleDown",
  CaretCircleDoubleLeft = "CaretCircleDoubleLeft",
  CaretCircleDoubleRight = "CaretCircleDoubleRight",
  CaretCircleDoubleUp = "CaretCircleDoubleUp",
  CaretCircleDown = "CaretCircleDown",
  CaretCircleLeft = "CaretCircleLeft",
  CaretCircleRight = "CaretCircleRight",
  CaretCircleUp = "CaretCircleUp",
  CaretCircleUpDown = "CaretCircleUpDown",
  CaretDoubleDown = "CaretDoubleDown",
  CaretDoubleLeft = "CaretDoubleLeft",
  CaretDoubleRight = "CaretDoubleRight",
  CaretDoubleUp = "CaretDoubleUp",
  CaretDown = "CaretDown",
  CaretLeft = "CaretLeft",
  CaretRight = "CaretRight",
  CaretUp = "CaretUp",
  CaretUpDown = "CaretUpDown",
  Carrot = "Carrot",
  CassetteTape = "CassetteTape",
  CastleTurret = "CastleTurret",
  Cat = "Cat",
  CellSignalFull = "CellSignalFull",
  CellSignalHigh = "CellSignalHigh",
  CellSignalLow = "CellSignalLow",
  CellSignalMedium = "CellSignalMedium",
  CellSignalNone = "CellSignalNone",
  CellSignalSlash = "CellSignalSlash",
  CellSignalX = "CellSignalX",
  Certificate = "Certificate",
  Chair = "Chair",
  Chalkboard = "Chalkboard",
  ChalkboardSimple = "ChalkboardSimple",
  ChalkboardTeacher = "ChalkboardTeacher",
  Champagne = "Champagne",
  ChargingStation = "ChargingStation",
  ChartBar = "ChartBar",
  ChartBarHorizontal = "ChartBarHorizontal",
  ChartDonut = "ChartDonut",
  ChartLine = "ChartLine",
  ChartLineDown = "ChartLineDown",
  ChartLineUp = "ChartLineUp",
  ChartPie = "ChartPie",
  ChartPieSlice = "ChartPieSlice",
  ChartPolar = "ChartPolar",
  ChartScatter = "ChartScatter",
  Chat = "Chat",
  ChatCentered = "ChatCentered",
  ChatCenteredDots = "ChatCenteredDots",
  ChatCenteredText = "ChatCenteredText",
  ChatCircle = "ChatCircle",
  ChatCircleDots = "ChatCircleDots",
  ChatCircleText = "ChatCircleText",
  ChatDots = "ChatDots",
  ChatTeardrop = "ChatTeardrop",
  ChatTeardropDots = "ChatTeardropDots",
  ChatTeardropText = "ChatTeardropText",
  ChatText = "ChatText",
  Chats = "Chats",
  ChatsCircle = "ChatsCircle",
  ChatsTeardrop = "ChatsTeardrop",
  Check = "Check",
  CheckCircle = "CheckCircle",
  CheckFat = "CheckFat",
  CheckSquare = "CheckSquare",
  CheckSquareOffset = "CheckSquareOffset",
  Checks = "Checks",
  Church = "Church",
  Circle = "Circle",
  CircleDashed = "CircleDashed",
  CircleHalf = "CircleHalf",
  CircleHalfTilt = "CircleHalfTilt",
  CircleNotch = "CircleNotch",
  CirclesFour = "CirclesFour",
  CirclesThree = "CirclesThree",
  CirclesThreePlus = "CirclesThreePlus",
  Circuitry = "Circuitry",
  Clipboard = "Clipboard",
  ClipboardText = "ClipboardText",
  ClockAfternoon = "ClockAfternoon",
  Clock = "Clock",
  ClockClockwise = "ClockClockwise",
  ClockCountdown = "ClockCountdown",
  ClockCounterClockwise = "ClockCounterClockwise",
  ClosedCaptioning = "ClosedCaptioning",
  CloudArrowDown = "CloudArrowDown",
  CloudArrowUp = "CloudArrowUp",
  Cloud = "Cloud",
  CloudCheck = "CloudCheck",
  CloudFog = "CloudFog",
  CloudLightning = "CloudLightning",
  CloudMoon = "CloudMoon",
  CloudRain = "CloudRain",
  CloudSlash = "CloudSlash",
  CloudSnow = "CloudSnow",
  CloudSun = "CloudSun",
  CloudWarning = "CloudWarning",
  CloudX = "CloudX",
  Club = "Club",
  CoatHanger = "CoatHanger",
  CodaLogo = "CodaLogo",
  CodeBlock = "CodeBlock",
  Code = "Code",
  CodeSimple = "CodeSimple",
  CodepenLogo = "CodepenLogo",
  CodesandboxLogo = "CodesandboxLogo",
  Coffee = "Coffee",
  Coin = "Coin",
  CoinVertical = "CoinVertical",
  Coins = "Coins",
  Columns = "Columns",
  Command = "Command",
  Compass = "Compass",
  CompassTool = "CompassTool",
  ComputerTower = "ComputerTower",
  Confetti = "Confetti",
  ContactlessPayment = "ContactlessPayment",
  Control = "Control",
  Cookie = "Cookie",
  CookingPot = "CookingPot",
  Copy = "Copy",
  CopySimple = "CopySimple",
  Copyleft = "Copyleft",
  Copyright = "Copyright",
  CornersIn = "CornersIn",
  CornersOut = "CornersOut",
  Couch = "Couch",
  Cpu = "Cpu",
  CreditCard = "CreditCard",
  Crop = "Crop",
  Cross = "Cross",
  Crosshair = "Crosshair",
  CrosshairSimple = "CrosshairSimple",
  Crown = "Crown",
  CrownSimple = "CrownSimple",
  Cube = "Cube",
  CubeFocus = "CubeFocus",
  CubeTransparent = "CubeTransparent",
  CurrencyBtc = "CurrencyBtc",
  CurrencyCircleDollar = "CurrencyCircleDollar",
  CurrencyCny = "CurrencyCny",
  CurrencyDollar = "CurrencyDollar",
  CurrencyDollarSimple = "CurrencyDollarSimple",
  CurrencyEth = "CurrencyEth",
  CurrencyEur = "CurrencyEur",
  CurrencyGbp = "CurrencyGbp",
  CurrencyInr = "CurrencyInr",
  CurrencyJpy = "CurrencyJpy",
  CurrencyKrw = "CurrencyKrw",
  CurrencyKzt = "CurrencyKzt",
  CurrencyNgn = "CurrencyNgn",
  CurrencyRub = "CurrencyRub",
  Cursor = "Cursor",
  CursorClick = "CursorClick",
  CursorText = "CursorText",
  Cylinder = "Cylinder",
  Database = "Database",
  Desktop = "Desktop",
  DesktopTower = "DesktopTower",
  Detective = "Detective",
  DevToLogo = "DevToLogo",
  DeviceMobile = "DeviceMobile",
  DeviceMobileCamera = "DeviceMobileCamera",
  DeviceMobileSpeaker = "DeviceMobileSpeaker",
  DeviceTablet = "DeviceTablet",
  DeviceTabletCamera = "DeviceTabletCamera",
  DeviceTabletSpeaker = "DeviceTabletSpeaker",
  Devices = "Devices",
  Diamond = "Diamond",
  DiamondsFour = "DiamondsFour",
  DiceFive = "DiceFive",
  DiceFour = "DiceFour",
  DiceOne = "DiceOne",
  DiceSix = "DiceSix",
  DiceThree = "DiceThree",
  DiceTwo = "DiceTwo",
  Disc = "Disc",
  DiscordLogo = "DiscordLogo",
  Divide = "Divide",
  Dna = "Dna",
  Dog = "Dog",
  Door = "Door",
  DoorOpen = "DoorOpen",
  Dot = "Dot",
  DotOutline = "DotOutline",
  DotsNine = "DotsNine",
  DotsSix = "DotsSix",
  DotsSixVertical = "DotsSixVertical",
  DotsThree = "DotsThree",
  DotsThreeCircle = "DotsThreeCircle",
  DotsThreeCircleVertical = "DotsThreeCircleVertical",
  DotsThreeOutline = "DotsThreeOutline",
  DotsThreeOutlineVertical = "DotsThreeOutlineVertical",
  DotsThreeVertical = "DotsThreeVertical",
  Download = "Download",
  DownloadSimple = "DownloadSimple",
  Dress = "Dress",
  DribbbleLogo = "DribbbleLogo",
  Drop = "Drop",
  DropHalf = "DropHalf",
  DropHalfBottom = "DropHalfBottom",
  DropboxLogo = "DropboxLogo",
  Ear = "Ear",
  EarSlash = "EarSlash",
  Egg = "Egg",
  EggCrack = "EggCrack",
  Eject = "Eject",
  EjectSimple = "EjectSimple",
  Elevator = "Elevator",
  Engine = "Engine",
  Envelope = "Envelope",
  EnvelopeOpen = "EnvelopeOpen",
  EnvelopeSimple = "EnvelopeSimple",
  EnvelopeSimpleOpen = "EnvelopeSimpleOpen",
  Equalizer = "Equalizer",
  Equals = "Equals",
  Eraser = "Eraser",
  EscalatorDown = "EscalatorDown",
  EscalatorUp = "EscalatorUp",
  Exam = "Exam",
  Exclude = "Exclude",
  ExcludeSquare = "ExcludeSquare",
  Export = "Export",
  Eye = "Eye",
  EyeClosed = "EyeClosed",
  EyeSlash = "EyeSlash",
  Eyedropper = "Eyedropper",
  EyedropperSample = "EyedropperSample",
  Eyeglasses = "Eyeglasses",
  FaceMask = "FaceMask",
  FacebookLogo = "FacebookLogo",
  Factory = "Factory",
  Faders = "Faders",
  FadersHorizontal = "FadersHorizontal",
  Fan = "Fan",
  FastForward = "FastForward",
  FastForwardCircle = "FastForwardCircle",
  Feather = "Feather",
  FigmaLogo = "FigmaLogo",
  FileArchive = "FileArchive",
  FileArrowDown = "FileArrowDown",
  FileArrowUp = "FileArrowUp",
  FileAudio = "FileAudio",
  File = "File",
  FileCloud = "FileCloud",
  FileCode = "FileCode",
  FileCss = "FileCss",
  FileCsv = "FileCsv",
  FileDashed = "FileDashed",
  FileDotted = "FileDotted",
  FileDoc = "FileDoc",
  FileHtml = "FileHtml",
  FileImage = "FileImage",
  FileJpg = "FileJpg",
  FileJs = "FileJs",
  FileJsx = "FileJsx",
  FileLock = "FileLock",
  FileMagnifyingGlass = "FileMagnifyingGlass",
  FileSearch = "FileSearch",
  FileMinus = "FileMinus",
  FilePdf = "FilePdf",
  FilePlus = "FilePlus",
  FilePng = "FilePng",
  FilePpt = "FilePpt",
  FileRs = "FileRs",
  FileSql = "FileSql",
  FileSvg = "FileSvg",
  FileText = "FileText",
  FileTs = "FileTs",
  FileTsx = "FileTsx",
  FileVideo = "FileVideo",
  FileVue = "FileVue",
  FileX = "FileX",
  FileXls = "FileXls",
  FileZip = "FileZip",
  Files = "Files",
  FilmReel = "FilmReel",
  FilmScript = "FilmScript",
  FilmSlate = "FilmSlate",
  FilmStrip = "FilmStrip",
  Fingerprint = "Fingerprint",
  FingerprintSimple = "FingerprintSimple",
  FinnTheHuman = "FinnTheHuman",
  Fire = "Fire",
  FireExtinguisher = "FireExtinguisher",
  FireSimple = "FireSimple",
  FirstAid = "FirstAid",
  FirstAidKit = "FirstAidKit",
  Fish = "Fish",
  FishSimple = "FishSimple",
  FlagBanner = "FlagBanner",
  Flag = "Flag",
  FlagCheckered = "FlagCheckered",
  FlagPennant = "FlagPennant",
  Flame = "Flame",
  Flashlight = "Flashlight",
  Flask = "Flask",
  FloppyDiskBack = "FloppyDiskBack",
  FloppyDisk = "FloppyDisk",
  FlowArrow = "FlowArrow",
  Flower = "Flower",
  FlowerLotus = "FlowerLotus",
  FlowerTulip = "FlowerTulip",
  FlyingSaucer = "FlyingSaucer",
  Folder = "Folder",
  FolderDashed = "FolderDashed",
  FolderDotted = "FolderDotted",
  FolderLock = "FolderLock",
  FolderMinus = "FolderMinus",
  FolderNotch = "FolderNotch",
  FolderNotchMinus = "FolderNotchMinus",
  FolderNotchOpen = "FolderNotchOpen",
  FolderNotchPlus = "FolderNotchPlus",
  FolderOpen = "FolderOpen",
  FolderPlus = "FolderPlus",
  FolderSimple = "FolderSimple",
  FolderSimpleDashed = "FolderSimpleDashed",
  FolderSimpleDotted = "FolderSimpleDotted",
  FolderSimpleLock = "FolderSimpleLock",
  FolderSimpleMinus = "FolderSimpleMinus",
  FolderSimplePlus = "FolderSimplePlus",
  FolderSimpleStar = "FolderSimpleStar",
  FolderSimpleUser = "FolderSimpleUser",
  FolderStar = "FolderStar",
  FolderUser = "FolderUser",
  Folders = "Folders",
  Football = "Football",
  Footprints = "Footprints",
  ForkKnife = "ForkKnife",
  FrameCorners = "FrameCorners",
  FramerLogo = "FramerLogo",
  Function = "Function",
  Funnel = "Funnel",
  FunnelSimple = "FunnelSimple",
  GameController = "GameController",
  Garage = "Garage",
  GasCan = "GasCan",
  GasPump = "GasPump",
  Gauge = "Gauge",
  Gavel = "Gavel",
  Gear = "Gear",
  GearFine = "GearFine",
  GearSix = "GearSix",
  GenderFemale = "GenderFemale",
  GenderIntersex = "GenderIntersex",
  GenderMale = "GenderMale",
  GenderNeuter = "GenderNeuter",
  GenderNonbinary = "GenderNonbinary",
  GenderTransgender = "GenderTransgender",
  Ghost = "Ghost",
  Gif = "Gif",
  Gift = "Gift",
  GitBranch = "GitBranch",
  GitCommit = "GitCommit",
  GitDiff = "GitDiff",
  GitFork = "GitFork",
  GitMerge = "GitMerge",
  GitPullRequest = "GitPullRequest",
  GithubLogo = "GithubLogo",
  GitlabLogo = "GitlabLogo",
  GitlabLogoSimple = "GitlabLogoSimple",
  Globe = "Globe",
  GlobeHemisphereEast = "GlobeHemisphereEast",
  GlobeHemisphereWest = "GlobeHemisphereWest",
  GlobeSimple = "GlobeSimple",
  GlobeStand = "GlobeStand",
  Goggles = "Goggles",
  GoodreadsLogo = "GoodreadsLogo",
  GoogleCardboardLogo = "GoogleCardboardLogo",
  GoogleChromeLogo = "GoogleChromeLogo",
  GoogleDriveLogo = "GoogleDriveLogo",
  GoogleLogo = "GoogleLogo",
  GooglePhotosLogo = "GooglePhotosLogo",
  GooglePlayLogo = "GooglePlayLogo",
  GooglePodcastsLogo = "GooglePodcastsLogo",
  Gradient = "Gradient",
  GraduationCap = "GraduationCap",
  Grains = "Grains",
  GrainsSlash = "GrainsSlash",
  Graph = "Graph",
  GridFour = "GridFour",
  GridNine = "GridNine",
  Guitar = "Guitar",
  Hamburger = "Hamburger",
  Hammer = "Hammer",
  Hand = "Hand",
  HandCoins = "HandCoins",
  HandEye = "HandEye",
  HandFist = "HandFist",
  HandGrabbing = "HandGrabbing",
  HandHeart = "HandHeart",
  HandPalm = "HandPalm",
  HandPointing = "HandPointing",
  HandSoap = "HandSoap",
  HandSwipeLeft = "HandSwipeLeft",
  HandSwipeRight = "HandSwipeRight",
  HandTap = "HandTap",
  HandWaving = "HandWaving",
  Handbag = "Handbag",
  HandbagSimple = "HandbagSimple",
  HandsClapping = "HandsClapping",
  HandsPraying = "HandsPraying",
  Handshake = "Handshake",
  HardDrive = "HardDrive",
  HardDrives = "HardDrives",
  Hash = "Hash",
  HashStraight = "HashStraight",
  Headlights = "Headlights",
  Headphones = "Headphones",
  Headset = "Headset",
  Heart = "Heart",
  HeartBreak = "HeartBreak",
  HeartHalf = "HeartHalf",
  HeartStraight = "HeartStraight",
  HeartStraightBreak = "HeartStraightBreak",
  Heartbeat = "Heartbeat",
  Hexagon = "Hexagon",
  HighHeel = "HighHeel",
  HighlighterCircle = "HighlighterCircle",
  Hoodie = "Hoodie",
  Horse = "Horse",
  Hourglass = "Hourglass",
  HourglassHigh = "HourglassHigh",
  HourglassLow = "HourglassLow",
  HourglassMedium = "HourglassMedium",
  HourglassSimple = "HourglassSimple",
  HourglassSimpleHigh = "HourglassSimpleHigh",
  HourglassSimpleLow = "HourglassSimpleLow",
  HourglassSimpleMedium = "HourglassSimpleMedium",
  House = "House",
  HouseLine = "HouseLine",
  HouseSimple = "HouseSimple",
  IceCream = "IceCream",
  IdentificationBadge = "IdentificationBadge",
  IdentificationCard = "IdentificationCard",
  Image = "Image",
  ImageSquare = "ImageSquare",
  Images = "Images",
  ImagesSquare = "ImagesSquare",
  InfiniteSymbol = "Infinity",
  Info = "Info",
  InstagramLogo = "InstagramLogo",
  Intersect = "Intersect",
  IntersectSquare = "IntersectSquare",
  IntersectThree = "IntersectThree",
  Jeep = "Jeep",
  Kanban = "Kanban",
  Key = "Key",
  KeyReturn = "KeyReturn",
  Keyboard = "Keyboard",
  Keyhole = "Keyhole",
  Knife = "Knife",
  Ladder = "Ladder",
  LadderSimple = "LadderSimple",
  Lamp = "Lamp",
  Laptop = "Laptop",
  Layout = "Layout",
  Leaf = "Leaf",
  Lifebuoy = "Lifebuoy",
  Lightbulb = "Lightbulb",
  LightbulbFilament = "LightbulbFilament",
  Lighthouse = "Lighthouse",
  LightningA = "LightningA",
  Lightning = "Lightning",
  LightningSlash = "LightningSlash",
  LineSegment = "LineSegment",
  LineSegments = "LineSegments",
  Link = "Link",
  LinkBreak = "LinkBreak",
  LinkSimple = "LinkSimple",
  LinkSimpleBreak = "LinkSimpleBreak",
  LinkSimpleHorizontal = "LinkSimpleHorizontal",
  LinkSimpleHorizontalBreak = "LinkSimpleHorizontalBreak",
  LinkedinLogo = "LinkedinLogo",
  LinuxLogo = "LinuxLogo",
  List = "List",
  ListBullets = "ListBullets",
  ListChecks = "ListChecks",
  ListDashes = "ListDashes",
  ListMagnifyingGlass = "ListMagnifyingGlass",
  ListNumbers = "ListNumbers",
  ListPlus = "ListPlus",
  Lock = "Lock",
  LockKey = "LockKey",
  LockKeyOpen = "LockKeyOpen",
  LockLaminated = "LockLaminated",
  LockLaminatedOpen = "LockLaminatedOpen",
  LockOpen = "LockOpen",
  LockSimple = "LockSimple",
  LockSimpleOpen = "LockSimpleOpen",
  Lockers = "Lockers",
  MagicWand = "MagicWand",
  Magnet = "Magnet",
  MagnetStraight = "MagnetStraight",
  MagnifyingGlass = "MagnifyingGlass",
  MagnifyingGlassMinus = "MagnifyingGlassMinus",
  MagnifyingGlassPlus = "MagnifyingGlassPlus",
  MapPin = "MapPin",
  MapPinLine = "MapPinLine",
  MapTrifold = "MapTrifold",
  MarkerCircle = "MarkerCircle",
  Martini = "Martini",
  MaskHappy = "MaskHappy",
  MaskSad = "MaskSad",
  MathOperations = "MathOperations",
  Medal = "Medal",
  MedalMilitary = "MedalMilitary",
  MediumLogo = "MediumLogo",
  Megaphone = "Megaphone",
  MegaphoneSimple = "MegaphoneSimple",
  MessengerLogo = "MessengerLogo",
  MetaLogo = "MetaLogo",
  Metronome = "Metronome",
  Microphone = "Microphone",
  MicrophoneSlash = "MicrophoneSlash",
  MicrophoneStage = "MicrophoneStage",
  MicrosoftExcelLogo = "MicrosoftExcelLogo",
  MicrosoftOutlookLogo = "MicrosoftOutlookLogo",
  MicrosoftPowerpointLogo = "MicrosoftPowerpointLogo",
  MicrosoftTeamsLogo = "MicrosoftTeamsLogo",
  MicrosoftWordLogo = "MicrosoftWordLogo",
  Minus = "Minus",
  MinusCircle = "MinusCircle",
  MinusSquare = "MinusSquare",
  Money = "Money",
  Monitor = "Monitor",
  MonitorPlay = "MonitorPlay",
  Moon = "Moon",
  MoonStars = "MoonStars",
  Moped = "Moped",
  MopedFront = "MopedFront",
  Mosque = "Mosque",
  Motorcycle = "Motorcycle",
  Mountains = "Mountains",
  Mouse = "Mouse",
  MouseSimple = "MouseSimple",
  MusicNote = "MusicNote",
  MusicNoteSimple = "MusicNoteSimple",
  MusicNotes = "MusicNotes",
  MusicNotesPlus = "MusicNotesPlus",
  MusicNotesSimple = "MusicNotesSimple",
  NavigationArrow = "NavigationArrow",
  Needle = "Needle",
  Newspaper = "Newspaper",
  NewspaperClipping = "NewspaperClipping",
  Notches = "Notches",
  NoteBlank = "NoteBlank",
  Note = "Note",
  NotePencil = "NotePencil",
  Notebook = "Notebook",
  Notepad = "Notepad",
  Notification = "Notification",
  NotionLogo = "NotionLogo",
  NumberCircleEight = "NumberCircleEight",
  NumberCircleFive = "NumberCircleFive",
  NumberCircleFour = "NumberCircleFour",
  NumberCircleNine = "NumberCircleNine",
  NumberCircleOne = "NumberCircleOne",
  NumberCircleSeven = "NumberCircleSeven",
  NumberCircleSix = "NumberCircleSix",
  NumberCircleThree = "NumberCircleThree",
  NumberCircleTwo = "NumberCircleTwo",
  NumberCircleZero = "NumberCircleZero",
  NumberEight = "NumberEight",
  NumberFive = "NumberFive",
  NumberFour = "NumberFour",
  NumberNine = "NumberNine",
  NumberOne = "NumberOne",
  NumberSeven = "NumberSeven",
  NumberSix = "NumberSix",
  NumberSquareEight = "NumberSquareEight",
  NumberSquareFive = "NumberSquareFive",
  NumberSquareFour = "NumberSquareFour",
  NumberSquareNine = "NumberSquareNine",
  NumberSquareOne = "NumberSquareOne",
  NumberSquareSeven = "NumberSquareSeven",
  NumberSquareSix = "NumberSquareSix",
  NumberSquareThree = "NumberSquareThree",
  NumberSquareTwo = "NumberSquareTwo",
  NumberSquareZero = "NumberSquareZero",
  NumberThree = "NumberThree",
  NumberTwo = "NumberTwo",
  NumberZero = "NumberZero",
  Nut = "Nut",
  NyTimesLogo = "NyTimesLogo",
  Octagon = "Octagon",
  OfficeChair = "OfficeChair",
  Option = "Option",
  OrangeSlice = "OrangeSlice",
  Package = "Package",
  PaintBrush = "PaintBrush",
  PaintBrushBroad = "PaintBrushBroad",
  PaintBrushHousehold = "PaintBrushHousehold",
  PaintBucket = "PaintBucket",
  PaintRoller = "PaintRoller",
  Palette = "Palette",
  Pants = "Pants",
  PaperPlane = "PaperPlane",
  PaperPlaneRight = "PaperPlaneRight",
  PaperPlaneTilt = "PaperPlaneTilt",
  Paperclip = "Paperclip",
  PaperclipHorizontal = "PaperclipHorizontal",
  Parachute = "Parachute",
  Paragraph = "Paragraph",
  Parallelogram = "Parallelogram",
  Park = "Park",
  Password = "Password",
  Path = "Path",
  PatreonLogo = "PatreonLogo",
  Pause = "Pause",
  PauseCircle = "PauseCircle",
  PawPrint = "PawPrint",
  PaypalLogo = "PaypalLogo",
  Peace = "Peace",
  Pen = "Pen",
  PenNib = "PenNib",
  PenNibStraight = "PenNibStraight",
  Pencil = "Pencil",
  PencilCircle = "PencilCircle",
  PencilLine = "PencilLine",
  PencilSimple = "PencilSimple",
  PencilSimpleLine = "PencilSimpleLine",
  PencilSimpleSlash = "PencilSimpleSlash",
  PencilSlash = "PencilSlash",
  Pentagram = "Pentagram",
  Pepper = "Pepper",
  Percent = "Percent",
  PersonArmsSpread = "PersonArmsSpread",
  Person = "Person",
  PersonSimpleBike = "PersonSimpleBike",
  PersonSimple = "PersonSimple",
  PersonSimpleRun = "PersonSimpleRun",
  PersonSimpleThrow = "PersonSimpleThrow",
  PersonSimpleWalk = "PersonSimpleWalk",
  Perspective = "Perspective",
  Phone = "Phone",
  PhoneCall = "PhoneCall",
  PhoneDisconnect = "PhoneDisconnect",
  PhoneIncoming = "PhoneIncoming",
  PhoneOutgoing = "PhoneOutgoing",
  PhonePlus = "PhonePlus",
  PhoneSlash = "PhoneSlash",
  PhoneX = "PhoneX",
  PhosphorLogo = "PhosphorLogo",
  Pi = "Pi",
  PianoKeys = "PianoKeys",
  PictureInPicture = "PictureInPicture",
  PiggyBank = "PiggyBank",
  Pill = "Pill",
  PinterestLogo = "PinterestLogo",
  Pinwheel = "Pinwheel",
  Pizza = "Pizza",
  Placeholder = "Placeholder",
  Planet = "Planet",
  Plant = "Plant",
  Play = "Play",
  PlayCircle = "PlayCircle",
  PlayPause = "PlayPause",
  Playlist = "Playlist",
  Plug = "Plug",
  PlugCharging = "PlugCharging",
  Plugs = "Plugs",
  PlugsConnected = "PlugsConnected",
  Plus = "Plus",
  PlusCircle = "PlusCircle",
  PlusMinus = "PlusMinus",
  PlusSquare = "PlusSquare",
  PokerChip = "PokerChip",
  PoliceCar = "PoliceCar",
  Polygon = "Polygon",
  Popcorn = "Popcorn",
  PottedPlant = "PottedPlant",
  Power = "Power",
  Prescription = "Prescription",
  Presentation = "Presentation",
  PresentationChart = "PresentationChart",
  Printer = "Printer",
  Prohibit = "Prohibit",
  ProhibitInset = "ProhibitInset",
  ProjectorScreen = "ProjectorScreen",
  ProjectorScreenChart = "ProjectorScreenChart",
  Pulse = "Pulse",
  Activity = "Activity",
  PushPin = "PushPin",
  PushPinSimple = "PushPinSimple",
  PushPinSimpleSlash = "PushPinSimpleSlash",
  PushPinSlash = "PushPinSlash",
  PuzzlePiece = "PuzzlePiece",
  QrCode = "QrCode",
  Question = "Question",
  Queue = "Queue",
  Quotes = "Quotes",
  Radical = "Radical",
  Radio = "Radio",
  RadioButton = "RadioButton",
  Radioactive = "Radioactive",
  Rainbow = "Rainbow",
  RainbowCloud = "RainbowCloud",
  ReadCvLogo = "ReadCvLogo",
  Receipt = "Receipt",
  ReceiptX = "ReceiptX",
  Record = "Record",
  Rectangle = "Rectangle",
  Recycle = "Recycle",
  RedditLogo = "RedditLogo",
  Repeat = "Repeat",
  RepeatOnce = "RepeatOnce",
  Rewind = "Rewind",
  RewindCircle = "RewindCircle",
  RoadHorizon = "RoadHorizon",
  Robot = "Robot",
  Rocket = "Rocket",
  RocketLaunch = "RocketLaunch",
  Rows = "Rows",
  Rss = "Rss",
  RssSimple = "RssSimple",
  Rug = "Rug",
  Ruler = "Ruler",
  Scales = "Scales",
  Scan = "Scan",
  Scissors = "Scissors",
  Scooter = "Scooter",
  Screencast = "Screencast",
  ScribbleLoop = "ScribbleLoop",
  Scroll = "Scroll",
  Seal = "Seal",
  CircleWavy = "CircleWavy",
  SealCheck = "SealCheck",
  CircleWavyCheck = "CircleWavyCheck",
  SealQuestion = "SealQuestion",
  CircleWavyQuestion = "CircleWavyQuestion",
  SealWarning = "SealWarning",
  CircleWavyWarning = "CircleWavyWarning",
  SelectionAll = "SelectionAll",
  SelectionBackground = "SelectionBackground",
  Selection = "Selection",
  SelectionForeground = "SelectionForeground",
  SelectionInverse = "SelectionInverse",
  SelectionPlus = "SelectionPlus",
  SelectionSlash = "SelectionSlash",
  Shapes = "Shapes",
  Share = "Share",
  ShareFat = "ShareFat",
  ShareNetwork = "ShareNetwork",
  Shield = "Shield",
  ShieldCheck = "ShieldCheck",
  ShieldCheckered = "ShieldCheckered",
  ShieldChevron = "ShieldChevron",
  ShieldPlus = "ShieldPlus",
  ShieldSlash = "ShieldSlash",
  ShieldStar = "ShieldStar",
  ShieldWarning = "ShieldWarning",
  ShirtFolded = "ShirtFolded",
  ShootingStar = "ShootingStar",
  ShoppingBag = "ShoppingBag",
  ShoppingBagOpen = "ShoppingBagOpen",
  ShoppingCart = "ShoppingCart",
  ShoppingCartSimple = "ShoppingCartSimple",
  Shower = "Shower",
  Shrimp = "Shrimp",
  ShuffleAngular = "ShuffleAngular",
  Shuffle = "Shuffle",
  ShuffleSimple = "ShuffleSimple",
  Sidebar = "Sidebar",
  SidebarSimple = "SidebarSimple",
  Sigma = "Sigma",
  SignIn = "SignIn",
  SignOut = "SignOut",
  Signature = "Signature",
  Signpost = "Signpost",
  SimCard = "SimCard",
  Siren = "Siren",
  SketchLogo = "SketchLogo",
  SkipBack = "SkipBack",
  SkipBackCircle = "SkipBackCircle",
  SkipForward = "SkipForward",
  SkipForwardCircle = "SkipForwardCircle",
  Skull = "Skull",
  SlackLogo = "SlackLogo",
  Sliders = "Sliders",
  SlidersHorizontal = "SlidersHorizontal",
  Slideshow = "Slideshow",
  SmileyAngry = "SmileyAngry",
  SmileyBlank = "SmileyBlank",
  Smiley = "Smiley",
  SmileyMeh = "SmileyMeh",
  SmileyNervous = "SmileyNervous",
  SmileySad = "SmileySad",
  SmileySticker = "SmileySticker",
  SmileyWink = "SmileyWink",
  SmileyXEyes = "SmileyXEyes",
  SnapchatLogo = "SnapchatLogo",
  Sneaker = "Sneaker",
  SneakerMove = "SneakerMove",
  Snowflake = "Snowflake",
  SoccerBall = "SoccerBall",
  SortAscending = "SortAscending",
  SortDescending = "SortDescending",
  SoundcloudLogo = "SoundcloudLogo",
  Spade = "Spade",
  Sparkle = "Sparkle",
  SpeakerHifi = "SpeakerHifi",
  SpeakerHigh = "SpeakerHigh",
  SpeakerLow = "SpeakerLow",
  SpeakerNone = "SpeakerNone",
  SpeakerSimpleHigh = "SpeakerSimpleHigh",
  SpeakerSimpleLow = "SpeakerSimpleLow",
  SpeakerSimpleNone = "SpeakerSimpleNone",
  SpeakerSimpleSlash = "SpeakerSimpleSlash",
  SpeakerSimpleX = "SpeakerSimpleX",
  SpeakerSlash = "SpeakerSlash",
  SpeakerX = "SpeakerX",
  Spinner = "Spinner",
  SpinnerGap = "SpinnerGap",
  Spiral = "Spiral",
  SplitHorizontal = "SplitHorizontal",
  SplitVertical = "SplitVertical",
  SpotifyLogo = "SpotifyLogo",
  Square = "Square",
  SquareHalf = "SquareHalf",
  SquareHalfBottom = "SquareHalfBottom",
  SquareLogo = "SquareLogo",
  SquareSplitHorizontal = "SquareSplitHorizontal",
  SquareSplitVertical = "SquareSplitVertical",
  SquaresFour = "SquaresFour",
  Stack = "Stack",
  StackOverflowLogo = "StackOverflowLogo",
  StackSimple = "StackSimple",
  Stairs = "Stairs",
  Stamp = "Stamp",
  StarAndCrescent = "StarAndCrescent",
  Star = "Star",
  StarFour = "StarFour",
  StarHalf = "StarHalf",
  StarOfDavid = "StarOfDavid",
  SteeringWheel = "SteeringWheel",
  Steps = "Steps",
  Stethoscope = "Stethoscope",
  Sticker = "Sticker",
  Stool = "Stool",
  Stop = "Stop",
  StopCircle = "StopCircle",
  Storefront = "Storefront",
  Strategy = "Strategy",
  StripeLogo = "StripeLogo",
  Student = "Student",
  Subtitles = "Subtitles",
  Subtract = "Subtract",
  SubtractSquare = "SubtractSquare",
  Suitcase = "Suitcase",
  SuitcaseRolling = "SuitcaseRolling",
  SuitcaseSimple = "SuitcaseSimple",
  Sun = "Sun",
  SunDim = "SunDim",
  SunHorizon = "SunHorizon",
  Sunglasses = "Sunglasses",
  Swap = "Swap",
  Swatches = "Swatches",
  SwimmingPool = "SwimmingPool",
  Sword = "Sword",
  Synagogue = "Synagogue",
  Syringe = "Syringe",
  TShirt = "TShirt",
  Table = "Table",
  Tabs = "Tabs",
  Tag = "Tag",
  TagChevron = "TagChevron",
  TagSimple = "TagSimple",
  Target = "Target",
  Taxi = "Taxi",
  TelegramLogo = "TelegramLogo",
  Television = "Television",
  TelevisionSimple = "TelevisionSimple",
  TennisBall = "TennisBall",
  Tent = "Tent",
  Terminal = "Terminal",
  TerminalWindow = "TerminalWindow",
  TestTube = "TestTube",
  TextAUnderline = "TextAUnderline",
  TextAa = "TextAa",
  TextAlignCenter = "TextAlignCenter",
  TextAlignJustify = "TextAlignJustify",
  TextAlignLeft = "TextAlignLeft",
  TextAlignRight = "TextAlignRight",
  TextB = "TextB",
  TextBolder = "TextBolder",
  TextColumns = "TextColumns",
  TextH = "TextH",
  TextHFive = "TextHFive",
  TextHFour = "TextHFour",
  TextHOne = "TextHOne",
  TextHSix = "TextHSix",
  TextHThree = "TextHThree",
  TextHTwo = "TextHTwo",
  TextIndent = "TextIndent",
  TextItalic = "TextItalic",
  TextOutdent = "TextOutdent",
  TextStrikethrough = "TextStrikethrough",
  TextT = "TextT",
  TextUnderline = "TextUnderline",
  Textbox = "Textbox",
  Thermometer = "Thermometer",
  ThermometerCold = "ThermometerCold",
  ThermometerHot = "ThermometerHot",
  ThermometerSimple = "ThermometerSimple",
  ThumbsDown = "ThumbsDown",
  ThumbsUp = "ThumbsUp",
  Ticket = "Ticket",
  TidalLogo = "TidalLogo",
  TiktokLogo = "TiktokLogo",
  Timer = "Timer",
  Tipi = "Tipi",
  ToggleLeft = "ToggleLeft",
  ToggleRight = "ToggleRight",
  Toilet = "Toilet",
  ToiletPaper = "ToiletPaper",
  Toolbox = "Toolbox",
  Tooth = "Tooth",
  Tote = "Tote",
  ToteSimple = "ToteSimple",
  Trademark = "Trademark",
  TrademarkRegistered = "TrademarkRegistered",
  TrafficCone = "TrafficCone",
  TrafficSign = "TrafficSign",
  TrafficSignal = "TrafficSignal",
  Train = "Train",
  TrainRegional = "TrainRegional",
  TrainSimple = "TrainSimple",
  Tram = "Tram",
  Translate = "Translate",
  Trash = "Trash",
  TrashSimple = "TrashSimple",
  Tray = "Tray",
  Tree = "Tree",
  TreeEvergreen = "TreeEvergreen",
  TreePalm = "TreePalm",
  TreeStructure = "TreeStructure",
  TrendDown = "TrendDown",
  TrendUp = "TrendUp",
  Triangle = "Triangle",
  Trophy = "Trophy",
  Truck = "Truck",
  TwitchLogo = "TwitchLogo",
  TwitterLogo = "TwitterLogo",
  Umbrella = "Umbrella",
  UmbrellaSimple = "UmbrellaSimple",
  Unite = "Unite",
  UniteSquare = "UniteSquare",
  Upload = "Upload",
  UploadSimple = "UploadSimple",
  Usb = "Usb",
  User = "User",
  UserCircle = "UserCircle",
  UserCircleGear = "UserCircleGear",
  UserCircleMinus = "UserCircleMinus",
  UserCirclePlus = "UserCirclePlus",
  UserFocus = "UserFocus",
  UserGear = "UserGear",
  UserList = "UserList",
  UserMinus = "UserMinus",
  UserPlus = "UserPlus",
  UserRectangle = "UserRectangle",
  UserSound = "UserSound",
  UserSquare = "UserSquare",
  UserSwitch = "UserSwitch",
  Users = "Users",
  UsersFour = "UsersFour",
  UsersThree = "UsersThree",
  Van = "Van",
  Vault = "Vault",
  Vibrate = "Vibrate",
  Video = "Video",
  VideoCamera = "VideoCamera",
  VideoCameraSlash = "VideoCameraSlash",
  Vignette = "Vignette",
  VinylRecord = "VinylRecord",
  VirtualReality = "VirtualReality",
  Virus = "Virus",
  Voicemail = "Voicemail",
  Volleyball = "Volleyball",
  Wall = "Wall",
  Wallet = "Wallet",
  Warehouse = "Warehouse",
  Warning = "Warning",
  WarningCircle = "WarningCircle",
  WarningDiamond = "WarningDiamond",
  WarningOctagon = "WarningOctagon",
  Watch = "Watch",
  WaveSawtooth = "WaveSawtooth",
  WaveSine = "WaveSine",
  WaveSquare = "WaveSquare",
  WaveTriangle = "WaveTriangle",
  Waveform = "Waveform",
  Waves = "Waves",
  Webcam = "Webcam",
  WebcamSlash = "WebcamSlash",
  WebhooksLogo = "WebhooksLogo",
  WechatLogo = "WechatLogo",
  WhatsappLogo = "WhatsappLogo",
  Wheelchair = "Wheelchair",
  WheelchairMotion = "WheelchairMotion",
  WifiHigh = "WifiHigh",
  WifiLow = "WifiLow",
  WifiMedium = "WifiMedium",
  WifiNone = "WifiNone",
  WifiSlash = "WifiSlash",
  WifiX = "WifiX",
  Wind = "Wind",
  WindowsLogo = "WindowsLogo",
  Wine = "Wine",
  Wrench = "Wrench",
  X = "X",
  XCircle = "XCircle",
  XSquare = "XSquare",
  YinYang = "YinYang",
  YoutubeLogo = "YoutubeLogo",
}

export interface SvgProps {
  color?: string;
  size?: number;
}

export interface IconCardProps {
  sys?: {
    id: string;
  };
  centered?: boolean;
  large?: boolean;
  className?: string;
  alignLeft?: boolean;
  description?: string;
  icon?: string;
  indicator?: string;
  svg?: keyof typeof Svg;
  theme?: "blue" | "green" | "purple" | "navy" | "orange";
  title?: string;
  iconWeight?: "thin" | "light" | "regular" | "bold" | "fill" | "duotone";
  fill?: boolean;
  url?: string;
  titleType?: "h2" | "h3";
}
