import { Occupation } from "./Occupation";

export interface TrainingResult {
  ctid: string;
  name: string;
  cipDefinition: CipDefinition;
  totalCost: number;
  percentEmployed: number | null;
  calendarLength: CalendarLength;
  totalClockHours?: number;
  inDemand: boolean;
  localExceptionCounty: string[];
  deliveryTypes: DeliveryType[];
  providerId: string | null;
  providerName: string | "Provider not available";
  availableAt: Address[];
  cities: string[];
  zipCodes: string[];
  highlight: string;
  rank: number;
  socCodes: string[];
  hasEveningCourses: boolean;
  languages: string[];
  isWheelchairAccessible: boolean;
  hasJobPlacementAssistance: boolean;
  hasChildcareAssistance: boolean;
}

export type Address = {
  "@type": "ceterms:Place";
  street_address?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  county?: string;
  targetContactPoints?: ContactPoint[];
}

type MetaData = {
  currentPage: number;
  totalPages: number;
  totalItems: number;
  itemsPerPage: number;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  nextPage: number | null;
  previousPage: number | null;
};

export interface TrainingData {
  data: TrainingResult[];
  meta: MetaData;
}

export interface Training {
  ctid: string;
  name: string;
  provider: Provider;
  availableAt: Address[];
  averageSalary: number | null;
  booksMaterialsCost: number;
  calendarLength: CalendarLength;
  credentials: ConditionProfile[];
  cipDefinition: CipDefinition;
  description: string;
  feesCost: number;
  hasChildcareAssistance: boolean;
  hasEveningCourses: boolean;
  hasJobPlacementAssistance: boolean;
  inDemand: boolean;
  isWheelchairAccessible: boolean;
  languages: string[];
  localExceptionCounty: string[];
  occupations: Occupation[];
  deliveryTypes: DeliveryType[];
  otherCost: number;
  percentEmployed: number | null;
  prerequisites: string;
  suppliesToolsCost: number;
  totalClockHours?: number;
  totalCost: number;
  tuitionCost: number;
}

export interface CipDefinition {
  cip: string;
  cipcode: string;
  ciptitle: string;
}

export interface Provider {
  ctid: string | null;
  providerId: string | null;
  name: string | "Provider not available";
  email?: string | null;
  url?: string | null;
  address?: Address[] | null;
}

export interface ContactPoint {
  name?: string;
  alternateName?: string;
  contactType?: string;
  email?: string[];
  telephone?: string[];
  faxNumber?: string[];
  socialMedia?: string[];
}

export interface CipDefinition {
  cip: string;
  cipcode: string;
  ciptitle: string;
}

export interface ConditionProfile {
  name?: string;
  experience?: string;
  description?: string;
  yearsOfExperience?: number;
  targetAssessment: ConditionProfileItem[];
  targetCompetency: ConditionProfileItem[];
  targetCredential: ConditionProfileItem[];
  targetLearningOpportunity: ConditionProfileItem[];
}

export interface ConditionProfileItem {
  name?: string;
  provider?: Provider;
  description?: string;
}

export enum CalendarLength {
  NULL = 0,
  LESS_THAN_ONE_DAY = 1,
  ONE_TO_TWO_DAYS = 2,
  THREE_TO_SEVEN_DAYS = 3,
  TWO_TO_THREE_WEEKS = 4,
  FOUR_TO_ELEVEN_WEEKS = 5,
  THREE_TO_FIVE_MONTHS = 6,
  SIX_TO_TWELVE_MONTHS = 7,
  THIRTEEN_MONTHS_TO_TWO_YEARS = 8,
  THREE_TO_FOUR_YEARS = 9,
  MORE_THAN_FOUR_YEARS = 10,
}

// https://credreg.net/ctdl/terms#Delivery
export enum DeliveryType {
  InPerson = "deliveryType:InPerson",
  OnlineOnly = "deliveryType:OnlineOnly",
  BlendedDelivery = "deliveryType:BlendedDelivery",
  VariableSite = "deliveryType:VariableSite"
}
